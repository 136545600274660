<template>
    <div class="container">
        <h3>信息绑定</h3>
        <div class="form-contain">
            <van-form @submit="onSubmit">
                <van-field
                    v-model="formObj.name"
                    label="姓名"
                    maxlength="15"
                    placeholder="请填写姓名"
                    required
                    :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <van-field
                    v-model="formObj.number"
                    label="学号"
                    maxlength="15"
                    placeholder="请填写学号"
                    required
                    :rules="[{ required: true, message: '请填写学号' }]"
                />
                <van-field
                    v-model="formObj.mobile"
                    label="手机号"
                    type="number"
                    maxlength="11"
                    placeholder="请填写手机号"
                    required
                    :rules="[{ pattern, message: '请填正确写手机号' }]"
                />              
                <van-field label="学校名称" v-model="schoolClass.schoolName" readonly />
                <van-field label="学院名称" v-model="schoolClass.facultyName" readonly />
                <van-field label="系名称"   v-model="schoolClass.seriesName" readonly />
                <van-field label="专业名称" v-model="schoolClass.majorName" readonly />
                <van-field label="班级名称" v-model="schoolClass.name" readonly />
              <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit" :disabled="loading">提交</van-button>
              </div>
            </van-form>
        </div>
    </div>
</template>

<script type="text/javascript">
    import { Toast } from "vant"
    export default {
        name: '',       
        data(){
            return {
                loading: false,
                formObj: {
                    name: '',
                    number: '',
                    mobile: ''
                },
                pattern: /1[0-9]{10}/,
                schoolClass: {
                    schoolName: '',
                    facultyName:'',
                    seriesName:'',
                    majorName: '',
                    name: ''
                }
            }
        },
        created(){
            this.formObj.classPk = this.$route.query.classPk
            this.getSchoolClass()
        },
        methods:{
            getSchoolClass(){
                let that = this
                this.$axios.post('https://taihu.eztserver.com/student/school/getGradesClassByPk',{pk: this.formObj.classPk}).then(res => {
                    if(res.status == '200'){
                        if (res.data.code == '0000') {
                            let dts = res.data.data
                            for(let i in that.schoolClass) {
                                that.schoolClass[i] = dts[i]
                            }
                        }
                    }
                })
            },
            onSubmit(){
                let p = {
                    ...this.formObj
                }
                p = JSON.parse(JSON.stringify(p))
                this.loading = true
                this.$axios.post('https://taihu.eztserver.com/student/account/registerByClass', p).then(res => {                                    
                    if(res.status == '200') { 
                        if (res.data.code == '0000') {
                            Toast.success('保存成功')
                        } else {
                            Toast.fail(res.data.msg);
                        }
                    }
                });         
            },
        }
    }
</script>
<style>
    .form-contain{
        margin: auto;
        width:90%;
    }
</style>
